<template>
  <div class="contact">
    <div class="page-section contact-us">
      <div class="page-container">
        <div class="w-layout-grid home-2-grid contact-us resetMargin">
          <div id="w-node-cb98fd365c2b-def694e9" class="title-text-container">
            <div class="title-text center-aligned">Contact Us</div>
          </div>
          <div
            @click="$router.absUrl('https://www.facebook.com/VedantaSpain/')"
            id="w-node-26e5db08b826-def694e9"
            class="social-media-icon contact-us cursorLinkPointer"
          >
            <img src="images/vedanta-facebook.svg" loading="lazy" alt="" />
          </div>
          <div id="w-node-d2c2da167278-def694e9" class="content-text-container">
            <div class="content-text left-aligned contact-us">
              We would love to hear from you!<br />Please use the below form to
              contact us and we would be in touch as soon as possible
            </div>
          </div>
          <div
            @click="$router.absUrl('https://www.instagram.com/vedantaspain/')"
            id="w-node-f6ec86120992-def694e9"
            class="social-media-icon contact-us cursorLinkPointer"
          >
            <img src="images/vedanta-instagram.svg" loading="lazy" alt="" />
          </div>
          <div
            id="w-node-fce15fda0afd-def694e9"
            class="contact-us-form-container"
          >
            <div class="contact-us-form">
              <div class="w-layout-grid contact-us-form-grid">
                <div
                  id="w-node-81431473803f-def694e9"
                  class="contact-us-form firstname"
                >
                  <div class="firstname contact-us firstNameField">
                    <v-text-field
                      name="firstName"
                      label="First Name"
                      placeholder="First Name"
                      solo
                      v-model="firstName"
                    ></v-text-field>
                  </div>
                </div>
                <div
                  id="w-node-ce19e2fde626-def694e9"
                  class="contact-us-form lastname"
                >
                  <div class="firstname contact-us lastname lastNameField">
                    <v-text-field
                      name="lastName"
                      label="Last Name"
                      placeholder="Last Name"
                      solo
                      v-model="lastName"
                    ></v-text-field>
                  </div>
                </div>
                <div
                  id="w-node-db652b699d90-def694e9"
                  class="contact-us-form firstname email"
                >
                  <div class="firstname contact-us emailAddressField">
                    <v-text-field
                      name="emailAddress"
                      label="E-mail address"
                      placeholder="E-mail address"
                      solo
                      v-model="emailAddress"
                    ></v-text-field>
                  </div>
                </div>
                <div
                  id="w-node-6ec24c556ad8-def694e9"
                  class="contact-us-form firstname message"
                >
                  <div class="firstname contact-us message messageField">
                    <v-textarea
                      class="messageTextArea"
                      no-resize
                      solo
                      name="messageText"
                      label="Your Message"
                      placeholder="Your Message"
                      v-model="messageText"
                      :height="messageTextAreaHeight"
                    ></v-textarea>
                  </div>
                </div>
                <div
                  id="w-node-80f577fd9f9e-def694e9"
                  class="contact-us-form lastname contactnumber"
                >
                  <div class="firstname contact-us lastname contactNumberField">
                    <v-text-field
                      name="contactNumber"
                      label="Contact Number (optional)"
                      placeholder="Contact Number (optional)"
                      solo
                      v-model="contactNumber"
                    ></v-text-field>
                  </div>
                </div>
                <div
                  @click.stop="sendMessage()"
                  id="w-node-f3d803a62c4d-def694e9"
                  class="button-container send-us-a-message"
                >
                  <div class="button-text">
                    <div class="button-text">
                      <v-btn
                        @click.stop="sendMessage()"
                        elevation="0"
                        :loading="loading"
                        :ripple="false"
                        class="primary sendMessageBtn"
                      >
                        <v-span class="contactUsButtonText">
                          SEND US A MESSAGE</v-span
                        ></v-btn
                      >
                    </div>
                  </div>
                  <div class="button-arrow">
                    <lottie-animation
                      :animation="ButtonArrowAnimation"
                      :animationSpeed="1.5"
                    ></lottie-animation>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            id="w-node-89090ff4ce16-def694e9"
            class="social-media-icon contact-us cursorLinkPointer"
            v-scroll-to="{
              el: '.emailVedanta',
              offset: -120,
              easing: [0.6, 0.8, 0.5, 1.1],
              duration: 700,
            }"
          >
            <img src="images/vedanta-mail.svg" loading="lazy" alt="" />
          </div>
          <div
            id="w-node-c633449f637e-def694e9"
            class="social-media-icon contact-us cursorLinkPointer"
            v-scroll-to="{
              el: '.phoneVedanta',
              offset: -120,
              easing: [0.6, 0.8, 0.5, 1.1],
              duration: 700,
            }"
          >
            <img src="images/vedanta-phone.svg" loading="lazy" alt="" />
          </div>
          <div
            id="w-node-8439515713fe-def694e9"
            class="social-media-icon contact-us location cursorLinkPointer"
            v-scroll-to="{
              el: '.locationVedanta',
              offset: -120,
              easing: [0.6, 0.8, 0.5, 1.1],
              duration: 700,
            }"
          >
            <img src="images/vedanta-location.svg" loading="lazy" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="page-section contact-us half">
      <div class="page-container contact-us">
        <div class="w-layout-grid contact-us-icons-grid">
          <div
            id="w-node-a5593974da78-def694e9"
            class="social-media-icon contact-us half"
          >
            <img src="images/vedanta-mail.svg" loading="lazy" alt="" />
          </div>
          <div
            id="w-node-39c6ceafef0f-def694e9"
            class="contact-us-text-area content-text left-aligned emailVedanta"
          >
            <a
              class="removeUnderlineFromLink"
              href="mailto:info@vedantaspain.com"
              ><div>info@vedantaspain.com</div></a
            >
          </div>
          <div class="social-media-icon contact-us half phoneIcon">
            <img src="images/vedanta-phone.svg" loading="lazy" alt="" />
          </div>
          <div
            class="contact-us-text-area content-text left-aligned phoneVedanta"
          >
            <div class="text-block">
              <v-col>
                <v-row>
                  <a class="removeUnderlineFromLink" href="tel:+34610984993"
                    >+34 610 984 993</a
                  >
                </v-row>
                <v-row>
                  <a class="removeUnderlineFromLink" href="tel:+34697251222"
                    >+34 697 251 222</a
                  >
                </v-row>
              </v-col>
            </div>
          </div>
          <div
            id="w-node-64cd7a9a4ca4-def694e9"
            class="social-media-icon contact-us location half locationVedanta"
          >
            <img src="images/vedanta-location.svg" loading="lazy" alt="" />
          </div>
          <div class="contact-us-text-area content-text left-aligned half">
            <div class="text-block-2">
              Carrer de l’Esparteria 3bis, 3<br />Barcelona 08003
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ButtonArrow from "../assets/LottieAnimations/join-our-next-session-icon.json";
import LottieAnimation from "../components/LottieAnimationComponent";

export default {
  name: "Contact-Us",
  components: {
    LottieAnimation,
  },
  data() {
    return {
      ButtonArrowAnimation: ButtonArrow,
      firstName: "",
      lastName: "",
      emailAddress: "",
      contactNumber: "",
      messageText: "",
      errorText: "",
    };
  },
  computed: {
    loading() {
      return this.$store.getters.loading;
    },
    messageTextAreaHeight() {
      if (window.innerWidth > 479) {
        return 180;
      } else {
        return 110;
      }
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    sendMessage() {
      if (this.checkForm()) {
        console.log("Error found");
        /* this.$store.dispatch("showSnackBar", {
          text: this.errorText,
          color: "red",
          timeout: 0,
        }); */
        this.$store.dispatch("showErrorDialog", {
          title: "Missing Fields",
          text: this.errorText
        });
      } else {
        this.sendMail();
      }
    },
    checkForm() {
      var errorFound = false;
      var firstParam = true;
      var errorText = "Enter the following: ";
      if (this.firstName === "") {
        if (!firstParam) {
          errorText = errorText + ", ";
        } else {
          firstParam = false;
        }
        errorText = errorText + "First Name";
        errorFound = true;
      }
      if (this.lastName === "") {
        if (!firstParam) {
          errorText = errorText + ", ";
        } else {
          firstParam = false;
        }
        errorText = errorText + "Last Name";
        errorFound = true;
      }

      if (this.emailAddress === "") {
        if (!firstParam) {
          errorText = errorText + ", ";
        } else {
          firstParam = false;
        }
        errorText = errorText + "Email Address";
        errorFound = true;
      }
      if (this.messageText === "") {
        if (!firstParam) {
          errorText = errorText + ", ";
        } else {
          firstParam = false;
        }
        errorText = errorText + "Your message";
        errorFound = true;
      }

      console.log("Error: ", errorText);
      this.errorText = errorText;
      return errorFound;
    },
    async sendMail() {
      var subject = "Enquiry";
      var toEmail = "info@vedantaspain.com";
      var fromEmail = "vedanta-mailer@cloudcerebro.com";

      var body = `<h3>Enquiry</h3>
            <table>
            <tr>
                <td valign="top">
                  <b>First Name</b>
                </td>
                <td valign="top">
                  :
                </td>
                <td valign="top">
                  ${this.firstName}
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <b>Last Name</b>
                </td>
                <td valign="top">
                  :
                </td>
                <td valign="top">
                  ${this.lastName}
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <b>Email Address</b>
                </td>
                <td valign="top">
                  :
                </td>
                <td valign="top">
                  ${this.emailAddress}
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <b>Contact Number</b>
                </td>
                <td valign="top">
                  :
                </td>
                <td valign="top">
                  ${this.contactNumber}
                </td>
              </tr>
              
              <tr>
                <td valign="top">
                  <b>Message</b>
                </td>
                <td valign="top">
                  :
                </td>
                <td valign="top">
                  ${this.messageText}
                </td>
              </tr>
            </table>`;

      var mail = {
        fromEmail: fromEmail,
        toEmail: toEmail,
        subject: subject,
        body: body,
      };
      console.log("Enquiry details: ", mail);
      try {
        await this.$store.dispatch("sendDetailsByEmail", mail);
        this.$store.dispatch("showSnackBar", {
          text: "We'll get in touch with you shortly",
          color: "green",
          timeout: 4000,
        });
        this.eventMailDialog = false;
        this.resetForm();
      } catch (error) {
        console.log(error);
        this.$store.dispatch("showSnackBar", {
          text: "Email not sent. Try again",
          color: "red",
          timeout: 4000,
        });
      }
    },
    resetForm() {
      this.firstName = "";
      this.lastName = "";
      this.emailAddress = "";
      this.contactNumber = "";
      this.messageText = "";
      this.errorText = "";
    },
  },
};
</script>
<style>
.removeUnderlineFromLink {
  text-decoration-line: none;
}
.firstNameField .v-input__slot,
.lastNameField .v-input__slot,
.emailAddressField .v-input__slot,
.contactNumberField .v-input__slot {
  min-height: 30px !important;
  box-shadow: none !important;
  background: #ffffff00 !important;
}
.firstNameField .v-input__control,
.lastNameField .v-input__control,
.emailAddressField .v-input__control,
.contactNumberField .v-input__control {
  min-height: 30px !important;
  height: 30px !important;
}
.firstNameField .v-text-field__details,
.lastNameField .v-text-field__details,
.emailAddressField .v-text-field__details,
.contactNumberField .v-text-field__details {
  display: none !important;
}
.firstNameField input,
.lastNameField input,
.emailAddressField input,
.contactNumberField input {
  font-size: 14px !important;
  color: black !important;
}
.firstNameField,
.lastNameField,
.emailAddressField,
.contactNumberField {
  padding: 4px 4px !important;
}
.contact-us-form.firstname {
  padding: 4px 4px !important;
}
.contact-us-form.lastname {
  padding: 4px 4px !important;
}
.resetMargin {
  margin-top: 30px !important;
}
@media screen and (max-width: 479px) {
  .resetMargin {
    margin-top: 20px !important;
  }
}
.phoneIcon {
  align-self: center !important;
}
.messageTextArea {
  font-size: 14px !important;
}
.messageTextArea .v-label {
  font-size: 14px !important;
}
.messageField {
  padding: 4px 4px !important;
}
.messageField .v-text-field__details {
  display: none !important;
}
.messageField textarea {
  min-height: 40px;
  line-height: 1rem !important;
  font-size: 14px !important;
}
.messageField .v-label {
  top: 9px !important;
}
.messageField {
  height: 200px !important;
}
.messageField .v-input__slot {
  min-height: 30px !important;
  box-shadow: none !important;
  background: #ffffff00 !important;
}
.sendMessageBtn:focus::before {
  opacity: 0 !important;
}
.sendMessageBtn::before {
  color: transparent;
}

.sendMessageBtn:hover {
  color: red;
}
.sendMessageBtn {

    padding: 0 0 !important;
}
.contactUsButtonText {
  font-family: Nunito, sans-serif;
    color: #fefff5;
    font-size: 1rem;
    font-weight: 700;
    text-align: center;
    letter-spacing: 0;
}
@media screen and (max-width: 991px) {
  .contactUsButtonText {
        font-size: 1.05em;
  }
}
@media screen and (max-width: 767px) {

.contactUsButtonText {
    padding-left: 0.2rem;
    font-size: 1em;
    font-weight: 700;
}
}
@media screen and (max-width: 479px) {

.button-container.send-us-a-message {
    max-width: 210px !important;
}
}
</style>
